

import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import UserContext from "../../context/UserContext";
import { Link } from "react-router-dom";
import MainBg from "../../assets/images/auth_bg.jpg"
import { Mic } from "react-feather";
import ChatBg from "../../assets/images/KristenBg.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';


function SendIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m22 2-7 20-4-9-9-4Z" />
            <path d="M22 2 11 13" />
        </svg>
    )
}

const NewChatTheme = () => {

    const context = useContext(UserContext)
    const { user, setUser, host, MODEL_URL } = context
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const [skip, setSkip] = useState(0);
    const limit = 10; // Number of chats to fetch per request
    const chatContainerRef = useRef(null);

    const { chatDisplayRef } = useRef(null)

    const [messages, setMessages] = useState([{
        user: null,
        assistant: `Hi, ${user?.name}, to get you set up and started, I'd like to conduct a consultation where I'll ask you a few questions`
    },
    ])

    const [inputText, setInputText] = useState('');
    const [isGenerating, setIsGenerating] = useState(false)
    const [userQuery, setUserQuery] = useState('')

    const CloseRef = useRef(null)

    const handleInputChange = (event) => {
        setInputText(event.target.value);
    };

    const fetchChats = async (limit = 2, skip = 0) => {
        if (!hasMore) return;
        setIsLoading(true);
        const previousHeight = chatContainerRef.current.scrollHeight;
        try {

            const response = await axios.get(`${host}/api/question-chats?limit=${limit}&skip=${skip}`, {
                headers: {
                    // 'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'auth-token': localStorage.getItem('token')
                },
            });

            if (response.data.success) {
                // console.log(response.data.chats)
                // setMessages([...messages,...response.data.chats])
                setMessages((prevChats) => [...response.data.chats, ...prevChats]);
                setSkip((prevSkip) => prevSkip + limit);
                setHasMore(response.data.hasMore);

                // Maintain the scroll position after loading new messages
                setTimeout(() => {
                    const currentHeight = chatContainerRef.current.scrollHeight;
                    chatContainerRef.current.scrollTop = currentHeight - previousHeight;
                }, 1000);
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }


    function formatChatMessage(message) {
        // Replace newline characters with HTML line breaks
        let formattedMessage = message.replace(/\n/g, "<br>");

        // Replace **bold** with <strong>bold</strong>
        formattedMessage = formattedMessage.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

        return formattedMessage;
    }

    const RenderHTML = (props) => (<span dangerouslySetInnerHTML={{ __html: props.HTML }}></span>)


    const handleSendMsg = async (e) => {
        e.preventDefault();
        // setMessages([...messages, { user: inputText, assistant: null }])
        setIsGenerating(true)
        setUserQuery(inputText)
        setInputText('')
        try {

            const response = await axios.post(`${MODEL_URL}/question-chat-bot`, {
                headers: {
                    // 'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'auth-token': localStorage.getItem('token')
                },
                data: {
                    user: inputText,
                    // threads: user.threads,
                    // index: user.index
                }
            });
            setIsGenerating(false)

            // console.log(response)

            if (response.data.success) {
                let botReply = response.data.reply
                setMessages([...messages, { user: inputText, assistant: botReply }])
                setUser({ ...user, index: response.data.index, questionCountPerIndex: response.data.questionCountPerIndex })
                // console.log(user)
                // console.log({...user,index : response.data.index})

            } else {
                setIsGenerating(false)
                setMessages([...messages])

                toast(response.data.error, {
                    position: "top-right",
                    type: "error",
                    autoClose: 5000,

                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

            }
            setInputText('')
        } catch {
            setIsGenerating(false)
            setMessages([...messages])

            toast("Something went wrong", {
                position: "top-right",
                type: "error",
                autoClose: 5000,

                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    useEffect(() => {
        // console.log(Object.values(user.conversation_question_bot))
        // setMessages([...messages, ...user.conversation_question_bot])
        fetchChats()
    }, [])

    useEffect(() => {
        if (chatContainerRef.current && !isLoading) {
            // chatContainerRef.current.scrollIntoView({ block: "end" })
            // chatContainerRef.current.scrollBy(0, 5000)
        }
    }, [messages, userQuery]);


    const handleScroll = () => {
        if (chatContainerRef.current.scrollTop < 60 && !isLoading) {
            fetchChats(limit, skip);
        }
    };



    return (
        <div className="flex items-center justify-center h-full py-5 bg-gray-200" style={{ backgroundImage: `url(${MainBg})` }}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="bg-dark bg-opacity-50 w-[90vw] max-w-[600px] h-[90vh] p-4 rounded-lg shadow-lg">
                <div className="relative bg-white rounded-md overflow-hidden h-full" >
                    <img
                        // src="https://via.placeholder.com/150"
                        src={ChatBg}
                        alt="Profile"
                        className=" object-cover w-full h-full"
                    />
                    {/* <div className="absolute inset-0 bg-black bg-opacity-10 flex flex-col items-center justify-center text-white"></div> */}
                    <div className="absolute block overflow-y-scroll  justify-center min-h-[33%] max-h-[33%] items-end bottom-20 left-0 right-0 p-4 mx-4 bg-gray-400 bg-opacity-10 backdrop-blur-lg rounded-t-md" style={{ scrollbarWidth: "none", scrollBehavior: "smooth" }} ref={chatContainerRef} onScroll={(e) => handleScroll()}>

                        {/* Load More - Start */}
                        <div className="flex items-center justify-center py-4  ">
                            <div className="flex items-center space-x-2 border border-pink-500 rounded-full px-2 bg-pink-100">
                                {hasMore && <svg className="animate-spin antialiased h-5 w-5 text-pink-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                </svg>}
                                <span className="text-gray-700 font-medium">{hasMore ?"Loading More...": "Ended"}</span>
                            </div>
                        </div>

                        {/* Load More - End */}

                        {/* <button className="bg-pink-500 text-white py-2 px-4 rounded-full mb-2">
                            Start New Chat
                        </button> */}


                        {
                            messages && messages.map((msg, idx) => {
                                return <div key={idx}>
                                    {msg.user && <div key={idx} className="self-end flex text-black flex-col gap-1 mb-2">
                                        <div className=" bg-[#ffffff] bg-opacity-20 backdrop-blur-lg rounded-b-2xl rounded-tl-2xl p-4 text-base break-words max-w-[60%] self-end" style={{ padding: "8px 14px" }}>
                                            {msg?.user}
                                        </div>

                                    </div>}

                                    <div key={idx + 'a'} className="self-start flex flex-col gap-1 mb-2">
                                        <div
                                            // dangerouslySetInnerHTML={{ __html:formatChatMessage(msg?.assistant)}} 
                                            className=" bg-[#ffffff] bg-opacity-20 backdrop-blur-lg rounded-b-2xl rounded-tr-2xl p-4 text-base break-words max-w-[60%] self-start" style={{ padding: "8px 14px" }} >
                                            {/* {formatChatMessage(msg?.assistant)} */}
                                            {/* {msg?.assistant} */}
                                            {msg.assistant && <RenderHTML HTML={formatChatMessage(msg?.assistant)} />}
                                        </div>
                                    </div>

                                </div>
                            })
                        }

                        {userQuery && isGenerating &&
                            <>
                                <div className="self-end flex text-black flex-col gap-1">
                                    <div className=" bg-[#ffffff] rounded-b-2xl rounded-tl-2xl p-4 text-base break-words max-w-[60%] self-end" style={{ padding: "8px 14px" }}>
                                        {userQuery}
                                    </div>

                                </div>

                                <div className="self-start flex flex-col gap-1">
                                    <div className=" bg-[#ffffff] rounded-b-2xl rounded-tr-2xl p-4 text-base break-words max-w-[60%] max-sm:min-w-[100px] max-md:min-w-[200px] min-w-[200px] min-h-[5px]" style={{ padding: "8px 14px" }} >
                                        <ReactLoading type={"bubbles"} color={"#141414"} height={'20%'} width={'20%'} />

                                    </div>

                                </div>
                            </>
                        }
                    </div>





                    <div className="absolute flex gap-2 bottom-0 left-0 right-0 p-4 rounded-t-md">
                        <button disabled={inputText === '' ? true : false} className="rounded-full w-12 h-7 p-2  bg-pink-400 flex items-center justify-center" onClick={(e) => handleSendMsg(e)}>
                            <Mic className="w-4 h-4 p-2 " />
                            <span className="sr-only">Send</span>
                        </button>

                        <form className="flex gap-2 w-full" onSubmit={(e) => handleSendMsg(e)}>

                            <input
                                type="text"
                                className="w-full p-2 rounded-full bg-white bg-opacity-60 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-pink-500"
                                placeholder="Let's Chat..."
                                value={inputText}
                                onChange={handleInputChange}
                            />
                        </form>
                        <button disabled={inputText === '' ? true : false} className="rounded-full w-12 h-7 p-2  flex items-center justify-center bg-pink-400" onClick={(e) => handleSendMsg(e)}>
                            <SendIcon className="w-4 h-4 p-2 " />
                            <span className="sr-only">Send</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewChatTheme;
