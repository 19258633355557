import { useState } from "react";
import UserContext from "./UserContext";
import { redirect, useNavigate, useNavigation } from "react-router-dom";

const UserState = ({children}) =>{

  // const host = 'http://localhost:5000';
  // const MODEL_URL = 'http://localhost:5001'
  // const VOICE_MODEL_URL = 'http://localhost:5001'
  const host = 'https://conceivable-dashboard-node-goz8l.ondigitalocean.app'  
  const MODEL_URL = 'https://conceivable-dashboard-flask-4xxgb.ondigitalocean.app'
  const VOICE_MODEL_URL = 'https://conceivable-dashboard-flask-4xxgb.ondigitalocean.app'

    const [user, setUser] = useState(null)
    const [temperatures, setTemperatures] = useState([]);
    const [mostRecentDate, setMostRecentDate] = useState(null);
    const [dates, setDates] = useState(null);
    const [cycleData, setCycleData] = useState([]);
    const [mainChats, setMainChats] = useState([[{
      user: null,
      assistant: "Hi"
    }]]);
    const [questionChats, setQuestionChats] = useState([[{
      user: null,
      assistant: "Hi"
    }]]);
  

    const navigate = useNavigate()

    const getUser = async () =>  {
      try {

        //API Call
        const response = await fetch(`${host}/api/auth/get-user`, {
          method: 'POST',
          headers: {
            'Content-Type' : 'application/json',
            'auth-token' :localStorage.getItem('token'),
          },
        });
        const json = await response.json();
        console.log(json)

        if(json.error){
          localStorage.clear();
          navigate('/login')
          return
        } else{
          setUser(json)

        }


      } catch (error) {
        console.log(error)
        localStorage.clear();
        navigate('/login')
        // alert("Internal Server Error")
      }
      }  


    
    
    const logOut =  () => {
      localStorage.clear();
      navigate('/login')
    
    }


  
    

    return(
        <UserContext.Provider value={{host,MODEL_URL,VOICE_MODEL_URL, user, setUser,  getUser,logOut, temperatures, dates, setDates, setTemperatures, mostRecentDate, setMostRecentDate , cycleData, setCycleData, mainChats, setMainChats, questionChats, setQuestionChats}}>
            {children}
        </UserContext.Provider>
    )
}


export default UserState