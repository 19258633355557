import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AudioList = ({ userId }) => {
    const [audios, setAudios] = useState([{
        
            url: "https://conceivable.nyc3.digitaloceanspaces.com/audio/spotifydown.com%20-%20Chahe%20Kahiye%20Ram%20Ram%20Ram%20Ram%20-%20Ram%20Vandana.mp3",
            date:"12-12-24"
        
    }]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    // useEffect(() => {
    //     fetchAudios(currentPage);
    // }, [currentPage]);

    const fetchAudios = async (page) => {
        try {
            const response = await axios.get(`/audios?userId=${userId}&page=${page}&limit=10`);
            setAudios(response.data.audios);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching audio notes:', error);
        }
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <div className="audio-list-container">
            <h2 className="text-lg font-semibold mb-4">Your Audio Notes</h2>
            <ul className="audio-list mb-4">
                {audios.map((audio, index) => (
                    <li key={index} className="audio-item mb-2 flex items-center justify-between">
                        <span className="date">{audio.date}</span>
                        <audio controls>
                            <source src={audio.url} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    </li>
                ))}
            </ul>
            <div className="pagination flex justify-center items-center space-x-4">
                <button
                    className="px-4 py-2 text-sm bg-gray-300 rounded hover:bg-gray-400"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button
                    className="px-4 py-2 text-sm bg-gray-300 rounded hover:bg-gray-400"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default AudioList;
