import { useLocation, useNavigate, useNavigation, useRoutes } from "react-router-dom";
import  NewRoutes from "./routes/Router";
import { Suspense, useContext, useEffect, useState } from "react";
import UserContext from "./context/UserContext";
import axios from "axios";


const App = () => {
  const context = useContext(UserContext)
  const {user, getUser, host, MODEL_URL} = context
  const navigate = useNavigate()
  // useEffect(() => {
  //   try {
  //     if(localStorage.getItem('token')){  
  //       try {
  //         getUser()    
  //       } catch (error) {
  //         if (localStorage.getItem("token")) {
  //           localStorage.clear()
  //           alert("Server Error")
  //         } else{
  //           alert("Server Error")
  //         }
          
  //       }
  //       // navigate('/dashboard')
  //     }
  //     else{
        

  //     }
  //     if(window && !window.navigator.onLine){
  //         alert("You are Offline")
  //     }
      
  //   } catch (error) {

  //     if (localStorage.getItem("token")) {
  //       localStorage.clear()
  //     } else{
  //       alert("Server Error")
  //     }

      
  //   }
  //   // else{
  //     // if(window && window.navigator.onLine){


  //     // } else{

        
  //     // }
  //     // navigate('/login')
  //   // }
  //   // console.log(user, host)
  // }, [])

  useEffect(() => {
  
    if (localStorage.getItem("token")) {
      try{
        getUser();
        // navigate('/dashboard')

      } catch(e){
          localStorage.clear()
          // navigate("/login");
    

      }
    } else {
      // navigate("/login");
    }
  }, [localStorage.getItem("token")]);
  
  // const routing = useRoutes(Themeroutes);
  // const newrouting = useRoutes(newRoutes);

  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(null);

  const generateMenu = async ()=> {
    try{
      const response = await axios.post(`${MODEL_URL}/generate-menu`,{
        headers: {
          'Access-Control-Allow-Origin':'*',
          'auth-token': localStorage.getItem('token')
        },
        timeout: 1000*30
      });

      // console.log(response)
    }
    catch(error){
      console.log(error)
    }
  }

  // const addPersonalDetails = async ()=> {
  //   try{
  //     const response = await axios.post(`${MODEL_URL}/add-personal-details`,{
  //       headers: {
  //         'Access-Control-Allow-Origin':'*',
  //         'auth-token': localStorage.getItem('token')
  //       },
  //       timeout: 1000*30
  //     });

  //     console.log(response)
  //   }
  //   catch(error){
  //     console.log(error)
  //   }
  // }
  const updatePersonalDetails = async ()=> {
    try{
      const response = await axios.post(`${MODEL_URL}/update-personal-details`,{
        headers: {
          'Access-Control-Allow-Origin':'*',
          'auth-token': localStorage.getItem('token')
        },
        timeout: 1000*30
      });

      // console.log(response)
    }
    catch(error){
      console.log(error)
    }
  }

  const addPersonalDetails = async ()=> {
    console.log("adding personal details")

    try{

      const response = await axios.post(`${MODEL_URL}/add-personal-details`,{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*',
          'auth-token': localStorage.getItem('token')
        },
        data: {
          user: user,
          threads: user.threads,
          index: user.index
        }
      });
      
      // console.log(response)
      getUser()
    } catch(error){
      console.log(error)
    }

} 



  useEffect(() => {
    if(window && !window.navigator.onLine){
      location.reload()
    }
    // Store the previous location when the location changes
    if(prevLocation === '/dashboard/talk-with-chef' && location.pathname !=='/dashboard/talk-with-chef'){
      console.log("Generating Menu")
      generateMenu()
    }
    if((prevLocation === '/dashboard/question-bot/' && location.pathname !=='/dashboard/question-bot/' ) || (prevLocation === '/dashboard/question-bot' && location.pathname !=='/dashboard/question-bot')){
      getUser()
      if(!user?.hasPersonalDetails && user?.index>6){
        user && addPersonalDetails()
        getUser()
        console.log("first")
      }
      console.log("two")
      addPersonalDetails()
    }
    if(prevLocation === '/dashboard/talk-with-kirsten/' && location.pathname !=='/dashboard/talk-with-kirsten/'){
      console.log("Update Personal details")
      updatePersonalDetails()
    }
    if(prevLocation === '/dashboard/talk-with-kirsten-/' && location.pathname !=='/dashboard/talk-with-kirsten-/'){
      console.log("Update Personal details")
      updatePersonalDetails()
    }
    setPrevLocation(location.pathname);
    

  }, [location, prevLocation, user]);


  return (
<>

{/* {user &&  <div className="dark ">{routing}</div>} */}
  

  <div className="font-[Inter]">
    {/* {newrouting} */}
    <Suspense fallback={<div className="">Loading...</div>}>
          <NewRoutes  user={user}/>
        </Suspense>
  </div>
</>
  )
};

export default App;
