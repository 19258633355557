// import { lazy } from "react";
// import { Navigate, Outlet } from "react-router-dom";
// import Login from "../views/auth/Login.jsx";
// import Signup from "../views/auth/Signup.jsx";
// import Forgot from "../views/auth/Forgot.jsx";
// import Verify from "../views/auth/Verify.jsx";
// import ResetPassword from "../views/auth/ResetPassword.jsx";
// import Profile from "../views/profile/Profile.jsx";
// import Logout from "../views/auth/Logout.jsx";
// import WeekMenu from "../components/weekMenu/WeekMenu.jsx";
// import MainChatBot from "../views/chatbot/MainChatBot.jsx";
// const SubscriptionPage = lazy(()=>import("../components/payment/stripe.jsx"));

// const FullLayout = lazy(() => import("../layouts/FullLayout.js"));
// const TongueAnalysis = lazy(()=> import("../views/TongueAnalysis/TongueAnalysis.jsx"))
// const Starter = lazy(() => import("../views/Starter.js"));
// const QuestionBot = lazy(() => import("../views/chatbot/QuestionBot.jsx"));
// const TherapyBot = lazy(() => import("../views/chatbot/TherapyBot.jsx"));
// const VoiceChatBot = lazy(() => import("../views/chatbot/VoiceChatBot.jsx"))
// const PaymentPlans = lazy(()=> import("../components/payment/PaymentPlans.jsx"))

// export const newRoutes = [
//   {
//     path: "/",
//     element: <><Outlet/></>,
//     children: [
//       { path: "/login", element: <Login /> },
//       { path: "/logout", element: <Logout /> },
//       { path: "/signup", element: <Signup /> },
//       { path: "/forgot", element: <Forgot /> },
//       { path: "/forgot-password/:token", exact: true, element: <ResetPassword /> },
//       { path: "/verify/:token", exact: true, element: <Verify /> },

//       {
//         path: "/dashboard",
//         element: <FullLayout />,
//         children: [
//           { path: "/dashboard", exact: true, element: <Starter /> },
//           { path: "/dashboard/question-bot/", element: <QuestionBot /> },
//           { path: "/dashboard/talk-with-expert/", element: <MainChatBot /> },
//           { path: "/dashboard/talk-with-chef", exact: true, element: <TherapyBot /> },
//           { path: "/dashboard/tongue-analysis", exact: true, element: <TongueAnalysis /> },
//           { path: "/dashboard/weeklyMenu", exact: true, element: <WeekMenu /> },
//           { path: "/dashboard/user-profile", exact: true, element: <Profile /> },
//           { path: "/dashboard/voice-chat-bot", exact: true, element: <VoiceChatBot /> },
//           { path: "/dashboard/payment-plans", exact: true, element: <PaymentPlans /> },
//           { path: "/dashboard/subscription", exact: true, element: <SubscriptionPage /> },

//         ],
//       },
//       { path: "*", exact: true, element: <Navigate to="/" /> },
//     ],
//   },
// ];

// let ThemeRoutes=[]

// export default ThemeRoutes;

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { lazy, useContext } from "react";
import UserContext from "../context/UserContext.jsx";
// import MainChatBotVoice from "../views/chatbot/MainChatBotVoice.jsx";
import React from "react";
import ContactUs from "../views/contact/ContactUs.jsx";
import NewChatTheme from "../views/chatbot/NewChatTheme.jsx";
import NewVoiceQuestionBot from "../views/chatbot/NewVoiceQuestionBot.jsx";
import NewVoiceBot from "../views/chatbot/NewVoiceBot.jsx";
import New2MainChatBot from "../views/chatbot/New2MainChatBot.jsx";
import AudioJournal from "../views/audio/AudioJournal.jsx";
const NewTherapyBot   = lazy(() => import("../views/chatbot/NewTherapyBot.jsx"));
const NewConsultancyBot  = lazy(() => import( "../views/chatbot/NewConsultancyBot.jsx"));
const NewMainChatBot  = lazy(() => import( "../views/chatbot/NewMainChatBot.jsx"));
const NewQuestionBot  = lazy(() => import("../views/chatbot/NewQuestionBot.jsx"));
const NewStress = lazy(() => import( "../views/chatbot/NewStress.jsx"));
const NewTCMBot = lazy(() => import("../views/chatbot/NewTCMBot.jsx"));
const MacroPage = lazy(() => import("../components/macros/MacroPage.jsx"));
const TCMBot = lazy(() => import("../views/chatbot/TCMBot.jsx"));
const StressBot = lazy(()=> import("../views/chatbot/Stress.jsx"));
const Learning = lazy(()=> import("../components/stress/Learning.jsx"));
const LockedDashboard =  lazy(() => import("../views/locked-dashboard/LockedDashboard.jsx"));
const Login = lazy(() => import("../views/auth/Login.jsx"));
const Signup = lazy(() => import("../views/auth/Signup.jsx"));
const Forgot = lazy(() => import("../views/auth/Forgot.jsx"));
const Verify = lazy(() => import("../views/auth/Verify.jsx"));
const ResetPassword = lazy(() => import("../views/auth/ResetPassword.jsx"));
const Profile = lazy(() => import("../views/profile/Profile.jsx"));
const Logout = lazy(() => import("../views/auth/Logout.jsx"));
const WeekMenu = lazy(() => import("../components/weekMenu/WeekMenu.jsx"));
const MainChatBot = lazy(() => import("../views/chatbot/MainChatBot.jsx"));
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));
const TongueAnalysis = lazy(() => import("../views/TongueAnalysis/TongueAnalysis.jsx"));
const Starter = lazy(() => import("../views/Starter.js"));
const QuestionBot = lazy(() => import("../views/chatbot/QuestionBot.jsx"));
const TherapyBot = lazy(() => import("../views/chatbot/TherapyBot.jsx"));
const VoiceChatBot = lazy(() => import("../views/chatbot/VoiceChatBot.jsx"));
const MainChatBotVoice = lazy(() =>
  import("../views/chatbot/MainChatBotVoice.jsx")
);
const PaymentPlans = lazy(() =>
  import("../components/payment/PaymentPlans.jsx")
);
const PreviousImages = lazy(() =>
  import("../views/TongueAnalysis/PreviousImages.jsx")
);
const ConsultancyBot = lazy(() =>
  import("../views/chatbot/ConsultancyBot.jsx")
);

const NewRoutes = ({ user }) => (
  <Routes>
    {( localStorage.getItem("token")) ? (
      <></>
    ) : (
      <Route path="/" element={<Outlet />}>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/forgot-password/:token" element={<ResetPassword />} />
        <Route path="/verify/:token" element={<Verify />} />


        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Route>
    )}
    <Route path="/logout" element={<Logout />} />
    <Route path="/dashboard" element={<FullLayout />}>
      
      {user && user.subscription.plan === "trial" && (
        <>
        {/* <Route path="/dashboard/locked-dashboard/" element={<LockedDashboard btnText="Sign Up"  text="Get Access Your Fully Personalized Fertility Coach with Your Subscription" btnLink = "/dashboard/consultancy-chat"/>} /> */}
        {/* <Route path="/dashboard/consultancy-chat/" element={<ConsultancyBot />}/> */}
        <Route path="/dashboard/consultancy-chat/" element={<NewConsultancyBot />}/>
        </>
      )}
      
      
      {(user &&
        user.subscription.plan !== "trial" &&
        !user?.hasPersonalDetails) &&
          <>
            {/* <Route path="/dashboard/locked-dashboard" element={<LockedDashboard/>} /> */}

            {/* <Route path="/dashboard/question-bot/" element={<QuestionBot />} /> */}
            <Route path="/dashboard/question-bot/" element={<NewQuestionBot />} />
            {/* <Route path="/dashboard/voice-chat-bot" element={<VoiceChatBot />}/> */}

          </>

          // :
          // <>
          //   <Route path="*" element={<Navigate to="/dashboard" />} />
          // </>
        }
      <Route path="/dashboard/" element={<Starter />} />
      <Route path="/dashboard/talk-with-kirsten-new/" element={<New2MainChatBot />}/>
      {user &&
      user?.hasPersonalDetails &&
      user?.subscription?.plan !== "trial" &&
      user?.subscription?.current_period_end > parseInt(Date.now() / 1000) ? (
        <>
          {/* <Route path="/dashboard/talk-with-kirsten/" element={<MainChatBot />}/> */}
          <Route path="/dashboard/talk-with-kirsten/" element={<NewMainChatBot />}/>
          <Route path="/dashboard/talk-with-kirsten-/" element={<MainChatBotVoice />}/> 
          {/* <Route path="/dashboard/talk-with-chef" element={<TherapyBot />} /> */}
          <Route path="/dashboard/talk-with-chef" element={<NewTherapyBot />} />
          <Route path="/dashboard/tongue-analysis" element={<TongueAnalysis />}/>
          <Route path="/dashboard/tongue-images" element={<PreviousImages />} />
          
          <Route path="/dashboard/weeklyMenu" element={<WeekMenu />} />
          <Route path="/dashboard/past-macros" element={<MacroPage />} />
        </>
      ) : (
        // <Route path="*" element={<LockedDashboard/>} />
        <></>
      )}
      
      <Route path="/dashboard/new-chat-theme" element={<NewChatTheme />}/>
      {/* <Route path="/dashboard/tcm" element={<TCMBot />}/> */}
      {/* <Route path="/dashboard/tcm" element={<NewTCMBot />}/> */}
      <Route path="/dashboard/audio-journal" element={<AudioJournal />}/>
      {/* <Route path="/dashboard/stress-relief" element={<StressBot />}/> */}
      <Route path="/dashboard/stress-relief" element={<NewStress />}/>
      <Route path="/dashboard/learnings" element={<Learning />} />
      <Route path="/dashboard/payment-plans" element={<PaymentPlans />} />
      <Route path="/dashboard/contactUs" element={<ContactUs/>}/>
      <Route path="/dashboard/user-profile" element={<Profile />} />
      <Route path="/dashboard/voice-chat-bot" element={<NewVoiceQuestionBot />}/>
    </Route>

    <Route path="*" element={<Navigate to="/dashboard" />} />
  </Routes>
);

export default NewRoutes;
