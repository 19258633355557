

import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import UserContext from "../../context/UserContext";
import { Link } from "react-router-dom";
import MainBg from "../../assets/images/auth_bg.jpg"
import { Mic, Music, Speaker, Volume, Volume2, VolumeX, X } from "react-feather";
import ChatBg from "../../assets/images/KristenBg.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { TypeAnimation } from "react-type-animation";


function SendIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m22 2-7 20-4-9-9-4Z" />
            <path d="M22 2 11 13" />
        </svg>
    )
}

const New2MainChatBot = () => {

    const [micBlocked, setMicBlocked] = useState(false);

    const context = useContext(UserContext)
    const { user, setUser, host, MODEL_URL } = context
    const [isLoading, setIsLoading] = useState(false);

    const chatContainerRef = useRef(null);

    const [messages, setMessages] = useState([
        //     {
        //     user: null,
        //     assistant: `Hi, ${user?.name}, to get you set up and started, I'd like to conduct a consultation where I'll ask you a few questions`
        // },
    ])

    const [inputText, setInputText] = useState('');
    const [isGenerating, setIsGenerating] = useState(false)
    const [isSpeaking, setIsSpeaking] = useState(false)
    const [userQuery, setUserQuery] = useState('')
    const [isVoiceMode, setIsVoiceMode] = useState(false);

    const [detectedText, setDetectedText] = useState('')
    const [firstLoad, setFirstLoaded] = useState(true)
    const CloseRef = useRef(null)

    const handleInputChange = (event) => {
        setInputText(event.target.value);
    };

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }




    function formatChatMessage(message) {
        // Replace newline characters with HTML line breaks
        let formattedMessage = message.replace(/\n/g, "<br>");

        // Replace **bold** with <strong>bold</strong>
        formattedMessage = formattedMessage.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

        return formattedMessage;
    }

    const RenderHTML = (props) => (<span dangerouslySetInnerHTML={{ __html:  props.HTML }}></span>)
    
    const typingSpeed = 20
    function typeWriter(txt) {
        if (i < txt.length) {
          document.getElementById("demo").innerHTML += txt.charAt(i);
          i++;
          setTimeout(typeWriter, typingSpeed);
        }
      }


    const handleSendMsg = async (e) => {
        e.preventDefault();
        setMessages([])
        // setMessages([...messages, { user: inputText, assistant: null }])
        setIsGenerating(true)
        setUserQuery(inputText)
        setInputText('')
        console.log(inputText)
        if(!inputText ) return
        try {

            const response = await axios.post(`${MODEL_URL}/main-chat-bot`, {
                headers: {
                    // 'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'auth-token': localStorage.getItem('token')
                },
                data: {
                    user: inputText,
                    // threads: user.threads,
                    // index: user.index
                }
            });
            setIsGenerating(false)

            console.log(response)

            if (response.data.success) {
                let botReply = response.data.reply
                // setMessages([...messages, { user: inputText, assistant: botReply }])
                setMessages([{ user: null, assistant: botReply }])
                setUser({ ...user, index: response.data.index, questionCountPerIndex: response.data.questionCountPerIndex })
                // console.log(user)
                // console.log({...user,index : response.data.index})

            } else {
                setIsGenerating(false)
                setMessages([...messages])

                toast(response.data.error, {
                    position: "top-right",
                    type: "error",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

            }
            setInputText('')
        } catch {
            setIsGenerating(false)
            setMessages([...messages])

            toast("Something went wrong", {
                position: "top-right",
                type: "error",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const handleSendMsgSTT = async (transcript) => {
        console.log(transcript)
        if (!transcript || transcript === '') {
            console.log("ret")
            return
        }
        console.log(" not ret")


        // setMessages([...messages, { user: inputText, assistant: null }]
        setUserQuery(transcript)
        console.log(transcript)
        try {

            const response = await axios.post(`${MODEL_URL}/main-chat-bot`, {
                headers: {
                    // 'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'auth-token': localStorage.getItem('token')
                },
                data: {
                    user: transcript,
                    // threads: user.threads,
                    // index: user.index
                }
            });
            setIsGenerating(false)

            // console.log(response)

            if (response.data.success) {
                let botReply = response.data.reply
                // setMessages([...messages, { user: transcript, assistant: botReply }])
                setMessages([{ user: null, assistant: botReply }])
                // setUser({ ...user})
                AudioStream(botReply)
                // console.log(user)
                // console.log({...user,index : response.data.index})

            } else {
                setIsGenerating(false)
                setMessages([...messages])

                toast(response.data.error, {
                    position: "top-right",
                    type: "error",
                    autoClose: 5000,

                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

            }

        } catch {
            setIsGenerating(false)

            setMessages([...messages])

            toast("Something went wrong", {
                position: "top-right",
                type: "error",
                autoClose: 5000,

                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    useEffect(() => {
        // console.log(Object.values(user.conversation_question_bot))
        // setMessages([...messages, ...user.conversation_question_bot])
        // fetchChats()
    }, [])

    useEffect(() => {
        if (chatContainerRef.current && !isLoading) {
            // chatContainerRef.current.scrollIntoView({ block: "end" })
            chatContainerRef.current.scrollBy(0, 5000)
        }
    }, [userQuery]);




    // //////////////Voice Chat /////////////


    useEffect(() => {
        console.log(transcript)
        console.log("first")
        if (!listening) {
            setIsGenerating(true)
            console.log("not listen")
            setUserQuery(transcript)
            console.log(transcript)
            setDetectedText(transcript);
            handleSendMsgSTT(transcript);
            resetTranscript();
            // if(detectedText === '') return;
        }
    }, [listening])

    const micToggle = async () => {
        if (!isVoiceMode) {
            setIsVoiceMode(true)
            return
        }

        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(() => {
                // User has granted microphone access
                setMicBlocked(false);
            })
            .catch((err) => {
                if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
                    // Microphone access is blocked
                    setMicBlocked(true);
                }
            });

        if (listening) {


            SpeechRecognition.stopListening()
            // setIsGenerating(true)
            // console.log("not listen")
            // setUserQuery(transcript)
            // console.log(transcript)
            // setDetectedText(transcript);
            // resetTranscript();
            // if(detectedText === '') return;
            // handleSendMsgSTT();
        } else {
            SpeechRecognition.startListening()


        }
    }

    useEffect(() => {
        if (chatContainerRef.current && !isLoading) {
            chatContainerRef.current.scrollBy(0, 5000)
            chatContainerRef.current.scrollIntoView({ block: "end" })
        }
    }, [transcript])




    //---------------------- TTS------------------------

    const audioRef = useRef(null);
    // const mediaSource = useRef(new MediaSource());

    const AudioStream = async (text) => {
        if (isSpeaking) return;
        setIsSpeaking(true)
        console.log("speaking")
        const url = "https://api.deepgram.com/v1/speak?model=aura-asteria-en";
        const apiKey = "2349d6026b7a71823fba4c082a3b10411d2be6d0";
        const data = {
            // text: "Hello, how can I help you today?",
            text: text
        };

        const config = {
            headers: {
                Authorization: `Token ${apiKey}`,
                "Content-Type": "application/json",
            },
            responseType: "arraybuffer", // Fetch as arraybuffer to handle binary data
        };

        try {

            const response = await axios.post(`${MODEL_URL}/stream-audio`, { text }, { responseType: 'arraybuffer' });
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const audioData = await audioContext.decodeAudioData(response.data);

            const source = audioContext.createBufferSource();
            source.buffer = audioData;
            source.connect(audioContext.destination);
            source.start();

            source.onended(() => {
                setIsSpeaking(false)
            })

            audioRef.current = source;
        } catch (error) {
            console.error("Error:", error.message);
            setIsSpeaking(false)
        } finally {
            // setIsSpeaking(false)
        }

         
    };

    const [displayedText, setDisplayedText] = useState('');
        useEffect(() => {
            const currentMessage = messages[0]?.assistant || '';
            const formattedMessage = formatChatMessage(currentMessage);
            let currentCharIndex = 0;
    
            if (currentMessage) {
                const intervalId = setInterval(() => {
                    if (currentCharIndex < formattedMessage.length) {
                        setDisplayedText(formattedMessage.slice(0, currentCharIndex + 1));
                        currentCharIndex++;
                    } else {
                        clearInterval(intervalId);
                        // Move to the next message or stop typing
                    }
                }, 50); // Adjust typing speed by changing the interval duration (in milliseconds)
    
                // Cleanup function to clear interval on component unmount or re-run
                return () => clearInterval(intervalId);
            }
        }, [0, messages]);
    
    return (
        <div className="flex items-center justify-center h-full py-5 bg-gray-200" style={{ backgroundImage: `url(${MainBg})` }}>
            {/* <audio ref={audioRef} className="hidden"/> */}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
                <img
                        // src="https://via.placeholder.com/150"
                        src={ChatBg}
                        alt="Profile"
                        // className=" object-cover w-full h-full"
                        className="fixed top-1 right-3 w-12 h-8 rounded-full z-[5555]"
                    />
            
            <>
                {micBlocked && (
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm mx-auto text-center">
                            <h2 className="text-xl font-bold mb-4">Microphone Access Blocked</h2>
                            <p className="mb-4">Please allow microphone access in your browser settings to continue.</p>
                            <button
                                onClick={() => setMicBlocked(false)}
                                className="bg-mediumHover text-white px-4 py-2 rounded hover:bg-medium focus:outline-none"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                )}
            </>
            <div className="bg-dark w-[90vw] max-w-[600px] h-[90vh] p-4 rounded-lg shadow-lg">
                <div className="relative rounded-md overflow-hidden h-full" >
                
                    <div className="content-center absolute block overflow-y-scroll  justify-center min-h-[100%] max-h-[100%] items-end bottom-[120px] left-0 right-0 p-4 mx-4  rounded-t-md text-white" style={{ scrollbarWidth: "none", scrollBehavior: "smooth" }} ref={chatContainerRef}
                    >


                        {
                            messages && messages.map((msg, idx) => {
                                return <div key={idx}>
                                    {msg.user && <div key={idx} className="self-end flex text-black flex-col gap-1 mb-2">
                            
                                        <div className="p-4 text-base md:text-lg font-medium  break-words max-w-[100%] self-end" style={{ padding: "8px 14px" }}>
                                            {msg?.user}
                                        </div>

                                    </div>}

                                    <div key={idx + 'a'} className="self-start flex flex-col gap-1 mb-2">
                                        <div className="p-4 text-base md:text-lg font-medium break-words max-w-[100%] overflow-hidden self-start"
                                            //  style={{ padding: "8px 14px" }}
                                            >
                                        
                                            {/* {msg.assistant && <RenderHTML HTML={formatChatMessage(msg?.assistant)} />} */}
                                            {displayedText && <RenderHTML HTML={displayedText} />}
                                        </div>
                                    </div>

                                </div>
                            })
                        }

                        {userQuery && isGenerating &&
                            <>
                                <div className="self-end flex text-white flex-col gap-1">
                                    {/* <div className=" bg-[#d3d3d345] bg-opacity-20 backdrop-blur-lg rounded-b-2xl rounded-tl-2xl p-4 text-xs md:text-sm font-medium  break-words max-w-[60%] self-end" style={{ padding: "8px 14px" }}> */}
                                    <div className="p-4 text-base md:text-lg font-medium  break-words max-w-[100%] self-end" style={{ padding: "8px 14px" }}>
                                        {userQuery}
                                    </div>

                                </div>

                
                            </>
                        }

                        {listening &&
                            <>
                                <div className="self-end flex text-white flex-col gap-1">
                                    {/* <div className=" bg-[#ffffff61] bg-opacity-20 backdrop-blur-lg rounded-b-2xl rounded-tl-2xl p-4 text-xs md:text-sm font-medium  break-words max-w-[60%] self-end" style={{ padding: "8px 14px" }}> */}
                                    <div className=" p-4 text-base md:text-lg font-medium  break-words max-w-[100%] self-end" style={{ padding: "8px 14px" }}>
                                        {transcript}
                                    </div>

                                </div>

                    
                            </>
                        }
                    </div>





                    <div className="justify-center absolute flex flex-wrap gap-2 bottom-0 left-0 right-0 py-4 px-0.5 rounded-t-md">
                

                        <div className="flex w-full gap-2">
                            
                            <form className={`flex gap-2 w-full ${isVoiceMode ? 'justify-center': ''} transform `} onSubmit={(e) => handleSendMsg(e)}>
                            <button className={`rounded-full  w-10 h-7 p-2  ${listening ? "bg-medium" : "bg-pink-400"} flex items-center justify-center`} style={{ transform: isVoiceMode ? 'scale(1.25)' : 'scale(1)' }} onClick={micToggle}>
                                <Mic className={`w-4 h-4 p-2 `} />
                                <span className="sr-only">Send</span>
                            </button>
                                <textarea
                                    type="text"
                                    className={`w-full p-2 rounded-full bg-white bg-opacity-60 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-pink-500 resize-none text-xs md:text-sm font-medium  content-center ${isVoiceMode ? "hidden" : ""} `}
                                    style={{ maxHeight: "calc(1.5em * 4)", scrollbarWidth: "none" }}
                                    placeholder="Let's Chat..."
                                    value={inputText}
                                    onChange={handleInputChange}
                                    rows="1"
                                />
                            </form>
                            {
                                !isVoiceMode ?
                                    <button disabled={inputText === '' ? true : false} className="rounded-full w-10 h-7 p-2  flex items-center justify-center bg-pink-400" onClick={(e) => handleSendMsg(e)}>
                                        <SendIcon className="w-4 h-4 p-2 " />
                                        <span className="sr-only">Send</span>
                                    </button>
                                    :
                                    <button className="rounded-full w-10 h-7 p-2  flex items-center justify-center bg-pink-400" onClick={() => setIsVoiceMode(false)}>
                                        <X className="w-4 h-4 p-2 " />
                                        <span className="sr-only">Send</span>
                                    </button>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default New2MainChatBot;
