// // import React from 'react';
// // import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

// // const Dictaphone = () => {
// //   const {
// //     transcript,
// //     listening,
// //     resetTranscript,
// //     browserSupportsSpeechRecognition
// //   } = useSpeechRecognition();

// //   if (!browserSupportsSpeechRecognition) {
// //     return <span>Browser doesn't support speech recognition.</span>;
// //   }

// //   return (
// //     <div>
// //       <p className='mx-2' >Microphone: {listening ? 'on' : 'off'}</p>
// //       <button className='mx-2' onClick={SpeechRecognition.startListening}>Start</button>
// //       <button className='mx-2' onClick={SpeechRecognition.stopListening}>Stop</button>
// //       <button className='mx-2' onClick={resetTranscript}>Reset</button>
// //       <p>{transcript}</p>
// //     </div>
// //   );
// // };


// // export default Dictaphone;



















// // import React, { useEffect } from 'react'
// // // import https from 'https'
// // const { createClient } = require("@deepgram/sdk");


// // const NewVoiceBot = () => {
// //   const deepgram = createClient("2349d6026b7a71823fba4c082a3b10411d2be6d0", {restProxy: { url: ""}});

// //   const text = "Hello, how can I help you today?";

// //   // const getAudio = async () => {
// //     // const response = await deepgram.speak.request(
// //     //   { text },
// //     //   {
// //     //     model: "aura-asteria-en",
// //     //     encoding: "linear16",
// //     //     container: "wav",
      
// //     //   },
      
// //     // );
// //     const url = 'https://api.deepgram.com/v1/speak?model=aura-asteria-en';
// //     const apiKey = "2349d6026b7a71823fba4c082a3b10411d2be6d0"
// //     const outputFilePath = "output_file.mp3";

// //     const body = JSON.stringify({
// //       text: "Hello, how can I help you today?",
// //     });

// //     const headers = {
// //       Authorization: `Token ${apiKey}`,
// //       "Content-Type": "application/json",
// //     };

// //     const options = {
// //       method: "POST",
// //       headers: headers,
// //       body: body,
// //     };

// //     async function streamAndPlayAudio() {
// //       // Create an audio element
// //       const audio = document.createElement('audio');
// //       audio.controls = true;
// //       audio.autoplay = true;
// //       document.body.appendChild(audio);
    
// //       // Create a MediaSource
// //       const mediaSource = new MediaSource();
// //       audio.src = URL.createObjectURL(mediaSource);
    
// //       mediaSource.addEventListener('sourceopen', async () => {
// //         const sourceBuffer = mediaSource.addSourceBuffer('audio/webm; codecs="opus"'); // Adjust MIME type as needed
    
// //         sourceBuffer.mode = 'sequence';

// //         fetchAudioStream(url, sourceBuffer, mediaSource);
    
// //       })
// //   }
// //     async function fetchAudioStream(url, sourceBuffer, mediaSource) {
// //       const response = await fetch(url);
// //       const reader = response.body.getReader();
    
// //       // Function to append received chunks to the source buffer
// //       const appendBlobToBuffer = async (blob) => {
// //         const arrayBuffer = await blob.arrayBuffer();
// //         sourceBuffer.appendBuffer(arrayBuffer);
// //       };
    
// //       // Function to process each chunk
// //       const processChunk = async ({ done, value }) => {
// //         if (done) {
// //           mediaSource.endOfStream();
// //           return;
// //         }
    
// //         const blob = new Blob([value], { type: 'audio/webm' }); // Adjust MIME type as needed
    
// //         if (sourceBuffer.updating) {
// //           sourceBuffer.addEventListener('updateend', () => appendBlobToBuffer(blob), { once: true });
// //         } else {
// //           await appendBlobToBuffer(blob);
// //         }
    
// //         reader.read().then(processChunk);
// //       };
    
// //       // Start reading the stream
// //       reader.read().then(processChunk);
// //     }

// //   //   fetch(url, options)
// //   // .then((response) => {
// //   //   if (!response.ok) {
// //   //     throw new Error("Failed to make request:", response.statusText);
// //   //   }
// //   //   return response.blob();
// //   // })
// //   // .then((blob) => {
// //   //   const reader = blob.stream().getReader();

// //   //   // const fileStream = fs.createWriteStream(outputFilePath);
// //   //   const fileStream  = new Blob([buffer], { type: 'audio/wav' });
// //   //   reader.read().then(function processText({ done, value }) {
// //   //     if (done) {
// //   //       console.log("File downloaded successfully.");
// //   //       return;
// //   //     }
// //   //     fileStream.write(Buffer.from(value));
// //   //     return reader.read().then(processText);
// //   //   });
// //   // })
// //   // .catch((error) => {
// //   //   console.error("Error:", error);
// //   // });

    

// //     // const options = { 
// //     //   method: 'POST', 
// //     //   headers: {'Content-Type': 'application/json'}, 
// //     //   body: JSON.stringify({text: 'Hello, how can I help you today?'}) 
// //     // }; 

// //     // let res = await fetch(url, options);

// //     // let myRes = await res.json();
// //     // console.log(myRes)
  

// //     // const stream = await response.getStream();
// //     // const headers = await response.getHeaders();
// //     // if (stream) {
// //     //   const buffer = await getAudioBuffer(stream);


// //     //   const audioBlob = new Blob([buffer], { type: 'audio/wav' });
// //     //   const audioUrl = URL.createObjectURL(audioBlob);

// //     //   // Create an audio element
// //     //   const audio = new Audio(audioUrl);
    
// //     //   // Play the audio
// //     //   audio.play();
    

// //       // fs.writeFile("output.wav", buffer, (err) => {
// //       //   if (err) {
// //       //     console.error("Error writing audio to file:", err);
// //       //   } else {
// //       //     console.log("Audio file written to output.wav");
// //       //   }
// //       // });
// //     // } else {
// //     //   console.error("Error generating audio:", stream);
// //     // }

// //     // if (headers) {
// //     //   console.log("Headers:", headers);
// //     // }
// //   // };

// //   // helper function to convert stream to audio buffer
// //   const getAudioBuffer = async (response) => {
// //     const reader = response.getReader();
// //     const chunks = [];

// //     while (true) {
// //       const { done, value } = await reader.read();
// //       if (done) break;

// //       chunks.push(value);
// //     }

// //     const dataArray = chunks.reduce(
// //       (acc, chunk) => Uint8Array.from([...acc, ...chunk]),
// //       new Uint8Array(0)
// //     );

// //     return Buffer.from(dataArray.buffer);
// //   };

// // useEffect(() => {
// //   // getAudio()
// // //   req.write(data);
// // // req.end();
// // streamAndPlayAudio()
// // }, [])



// //   return (
// //     <div>


// //     </div>
// //   )
// // }

// // export default NewVoiceBot




// // import React, { useEffect, useRef, useState } from 'react';
// // import axios from 'axios';

// // const NewVoiceBot = () => {
// //   const audioContext = useRef(null);
// //   const [audioBuffer, setAudioBuffer] = useState([]);
// //   const [sourceNode, setSourceNode] = useState(null);
// //   const audioRef = useRef(null);
// //   const mediaSource = useRef(new MediaSource());


// //   useEffect(() => {
// //     const fetchData = async () => {
// //       const url = "https://api.deepgram.com/v1/speak?model=aura-asteria-en";
// //       const apiKey = "2349d6026b7a71823fba4c082a3b10411d2be6d0";
// //       const data = {
// //         text: "Hello, how can I help you today?",
// //       };

// //       const config = {
// //         headers: {
// //           Authorization: `Token ${apiKey}`,
// //           "Content-Type": "application/json",
// //         },
// //         responseType: "arraybuffer", // Ensure the response is treated as a stream
// //       };

// //       try {
// //         const response = await axios.post(url, data, config);
        
// //         if (response.status !== 200) {
// //           console.error(`HTTP error! Status: ${response.status}`);
// //           return;
// //         }

// //         const audioElement = audioRef.current;
// //         const sourceBuffer = mediaSource.current.addSourceBuffer('audio/mpeg');

// //         mediaSource.current.addEventListener('sourceopen', () => {
// //           sourceBuffer.appendBuffer(response.data);
// //         });

// //         audioElement.src = URL.createObjectURL(mediaSource.current);
// //         audioElement.play();
// //       } catch (error) {
// //         console.error("Error:", error.message);
// //       }
// //     };

// //     fetchData();
// //   }, []);

// //   useEffect(() => {
// //     if (audioBuffer && audioContext.current) {
// //       const source = audioContext.current.createBufferSource();
// //       source.buffer = audioBuffer;
// //       source.connect(audioContext.current.destination);
// //       source.start(0);
// //       setSourceNode(source);
// //     }

// //     return () => {
// //       if (sourceNode) {
// //         sourceNode.stop();
// //       }
// //     };
// //   }, [audioBuffer]);

// //   return (
// //     <div>
// //       <h1>Audio Player</h1>
// //       <p>Playing streamed audio...</p>
// //       <audio ref={audioRef} controls />
// //     </div>
// //   );
// // };

// // export default NewVoiceBot;




// // import React, { useEffect, useRef } from 'react';
// // import axios from 'axios';

// // const AudioPlayer = () => {
// //   const audioRef = useRef(null);
// //   const mediaSource = useRef(new MediaSource());

// //   useEffect(() => {
// //     const fetchData = async () => {
// //       const url = "https://api.deepgram.com/v1/speak?model=aura-asteria-en";
// //       const apiKey = "DEEPGRAM_API_KEY";
// //       const data = {
// //         text: "Hello, how can I help you today?",
// //       };

// //       const config = {
// //         headers: {
// //           Authorization: `Token ${apiKey}`,
// //           "Content-Type": "application/json",
// //         },
// //         responseType: "arraybuffer", // Fetch as arraybuffer to handle binary data
// //       };

// //       try {
// //         const response = await axios.post(url, data, config);
        
// //         if (response.status !== 200) {
// //           console.error(`HTTP error! Status: ${response.status}`);
// //           return;
// //         }

// //         const audioElement = audioRef.current;
// //         mediaSource.current.addEventListener('sourceopen', () => {
// //           const sourceBuffer = mediaSource.current.addSourceBuffer('audio/mpeg');
// //           sourceBuffer.appendBuffer(response.data);
// //         });

// //         audioElement.src = URL.createObjectURL(mediaSource.current);
// //         audioElement.play();
// //       } catch (error) {
// //         console.error("Error:", error.message);
// //       }
// //     };

// //     fetchData();
// //   }, []);

// //   return (
// //     <div>
// //       <h1>Audio Player</h1>
// //       <audio ref={audioRef} controls />
// //     </div>
// //   );
// // };

// // export default AudioPlayer;



// import React, { useEffect, useRef } from 'react';
// import axios from 'axios';

// const NewVoiceBot = () => {
//   const audioRef = useRef(null);
//   const mediaSource = useRef(new MediaSource());

  
//     const AudioStream = async (text) => {
//       const url = "https://api.deepgram.com/v1/speak?model=aura-asteria-en";
//       const apiKey = "2349d6026b7a71823fba4c082a3b10411d2be6d0";
//       const data = {
//         // text: "Hello, how can I help you today?",
//         text:text
//       };

//       const config = {
//         headers: {
//           Authorization: `Token ${apiKey}`,
//           "Content-Type": "application/json",
//         },
//         responseType: "arraybuffer", // Fetch as arraybuffer to handle binary data
//       };

//       try {
//         const response = await axios.post(url, data, config);
        
//         if (response.status !== 200) {
//           console.error(`HTTP error! Status: ${response.status}`);
//           return;
//         }

//         const audioElement = audioRef.current;
//         mediaSource.current.addEventListener('sourceopen', () => {
//           const sourceBuffer = mediaSource.current.addSourceBuffer('audio/mpeg');
//           sourceBuffer.appendBuffer(response.data);
//         });

//         audioElement.src = URL.createObjectURL(mediaSource.current);
//         audioElement.play();
//       } catch (error) {
//         console.error("Error:", error.message);
//       }
//     };

//     useEffect(() => {
//       const audioElement = audioRef.current;
  
//       const handleEnded = () => {
//         audioElement.pause();
//         audioElement.currentTime = 0;
//         audioElement.load();
//       };
  
//       audioElement.addEventListener('ended', handleEnded);
  
//       return () => {
//         audioElement.removeEventListener('ended', handleEnded);
//       };
//     }, []);



//   return (
//     <div>
//       <h1>Audio Player</h1>
//       <audio ref={audioRef} controls />
//       <button  onClick={() => AudioStream("This is a sample text")}>Play</button>
//     </div>
//   );
// };

// export default NewVoiceBot;



// ///////Final TTs------------------------
// import axios from 'axios';
// import React, { useContext, useRef, useState } from 'react'
// import UserContext from '../../context/UserContext';

// const NewVoiceBot = () => {

//   const [text, setText] = useState('');
//   const audioRef = useRef(null);

//   const context = useContext(UserContext)
//   const { user, setUser, host, MODEL_URL } = context

//   const handlePlay = async () => {
//     try {
//       const response = await axios.post(`${MODEL_URL}/stream-audio`, { text }, { responseType: 'arraybuffer' });
//       const audioContext = new (window.AudioContext || window.webkitAudioContext)();
//       const audioData = await audioContext.decodeAudioData(response.data);

//       const source = audioContext.createBufferSource();
//       source.buffer = audioData;
//       source.connect(audioContext.destination);
//       source.start();

//       audioRef.current = source;
//     } catch (error) {
//       console.error('Error streaming audio:', error);
//     }
//   };

//   return (
//     <div className="p-4">
//       <textarea
//         className="w-full p-2 border rounded-md"
//         rows="4"
//         placeholder="Enter text for TTS"
//         value={text}
//         onChange={(e) => setText(e.target.value)}
//       />
//       <button
//         className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
//         onClick={handlePlay}
//       >
//         Play
//       </button>
//     </div>
//   );
// };




// // export default NewVoiceBot