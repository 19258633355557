import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import AudioList from './AudioList';

const AudioJournal = () =>  {
    const [recording, setRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const [timer, setTimer] = useState(90); // 90 seconds countdown
    const mediaRecorderRef = useRef(null);
    const timerRef = useRef(null);

    // Start recording audio
    const startRecording = async () => {
        setRecording(true);
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (event) => setAudioBlob(event.data);
        mediaRecorderRef.current.start();
        startTimer();
    };

    // Stop recording audio
    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        stopTimer();
        setRecording(false);
    };

    // Start the countdown timer
    const startTimer = () => {
        timerRef.current = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    stopRecording();
                    clearInterval(timerRef.current);
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);
    };

    // Stop the timer
    const stopTimer = () => {
        clearInterval(timerRef.current);
        setTimer(90); // Reset timer to 90 seconds
    };

    // Upload audio to the server
    const uploadAudio = async () => {
        if (!audioBlob) return;

        const formData = new FormData();
        formData.append('audio', audioBlob, 'note.mp3');

        try {
            await axios.post('http://localhost:5000/upload/audio-jounal', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            alert('Audio uploaded successfully!');
        } catch (error) {
            alert('Failed to upload audio');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                <h1 className="text-2xl font-bold mb-4">Daily Audio Note</h1>

                {/* Timer Display */}
                <div className="text-4xl font-semibold mb-4 text-red-500">
                    {`${Math.floor(timer / 60)}:${String(timer % 60).padStart(2, '0')}`}
                </div>

                {!recording ? (
                    <button
                        onClick={startRecording}
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                        Start Recording
                    </button>
                ) : (
                    <button
                        onClick={stopRecording}
                        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                    >
                        Stop Recording
                    </button>
                )}

                {/* Audio Preview */}
                {audioBlob && (
                    <div className="mt-4">
                        <audio controls src={URL.createObjectURL(audioBlob)} className="mb-4">
                            Your browser does not support the audio element.
                        </audio>
                        <button
                            onClick={uploadAudio}
                            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                        >
                            Upload Audio
                        </button>
                    </div>
                )}
            </div>
            <AudioList/>
        </div>
    );
}

export default AudioJournal;
